<template>
  <div class="app-container">
    <img class="background front" :style="{ transform: `scale(${backgroundScale})` }" src="@/assets/images/Design10 Front.png" alt="">
    <img class="background back" :style="{ transform: `scale(${backgroundScale})` }" src="@/assets/images/Design10 Back.png" alt="">
    <Transition name="fade">
      <div v-if="showHashtag" class="background hashtag mid">#RaFoReen</div>
    </Transition>
    
    <Transition name="slideup">
      <router-view v-if="!showHashtag" class="main-router mid" />
    </Transition>

    <div v-if="isDev" class="dev-section">
      <button :disabled="stateIsLoading" @click="updateTesting">{{ stateIsLoading ? 'LOADING' : (testingState == '1' ? 'DEV' : 'LIVE') }}</button>
      <input type="text" v-model="startDateTime" :disabled="startDateTimeIsLoading">
      <input type="text" v-model="endDateTime" :disabled="endDateTimeIsLoading">
      <button :disabled="startDateTimeIsLoading || endDateTimeIsLoading" @click="updateTime">Update Time</button>
    </div>
    <div v-else class="song-section" @click="songPlayPause" :style="{ animationPlayState: showHashtag ? 'paused' : 'running',
      opacity: canPlay ? '1' : '0.5', pointerEvents: canPlay ? 'auto' : 'none' }">
      <Transition name="playpause">
        <img v-if="songIsPlaying" src="@/assets/images/PauseButton.png" alt="">
        <img v-else src="@/assets/images/PlayButton.png" alt="">
      </Transition>
      <audio :src="audioSource ? require(`./assets/songs/${audioSource}`) : ''" ref="audioEl"
        @emptied="audioEmptied" @ended="audioEnded"></audio>
    </div>

    <Transition name="wish-photo-popup">
      <div v-if="store.state.showWishPhoto" class="show-photo-container">
        <img class="wish-photo-image" :src="store.state.wishPhotoSource">
        <img class="wish-photo-close" src="@/assets/images/Close.png" @click="store.state.showWishPhoto = false">
        <!-- <button class="wish-photo-download" @click="wishPhotoDownload">Download</button> -->
      </div>
    </Transition>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { api, simulateAPI } from './js/apihelper';

const store = useStore();

const showHashtag = ref(true);
const backgroundScale = ref('1');
const route = useRoute();

const isDev = ref(false);
const testingState = ref('1');
const stateIsLoading = ref(true);
const startDateTime = ref('Loading');
const startDateTimeIsLoading = ref(true);
const endDateTime = ref('Loading');
const endDateTimeIsLoading = ref(true);

onMounted(() => {
  songIndex.value = Math.floor(Math.random() * allSongs.value.length);
  audioSource.value = allSongs.value[songIndex.value];

  setTimeout(() => {
    isDev.value = route.params.who == 'dev' ? true : false;

    if (isDev.value) {
      stateIsLoading.value = true;
      startDateTimeIsLoading.value = true;
      endDateTimeIsLoading.value = true;

      getStartTime();
      getEndTime();
      
      api('/Settings/GetSettings', { setting: 'testing' }) // For live
      // simulateAPI(2000, null) // Simulate null
      // simulateAPI(2000, { value: '' }) // Simulate empty value
      // simulateAPI(2000, { value: '1' }) // Simulate with value 1 (DEV)
      // simulateAPI(2000, { value: '0' }) // Simulate with value 0 (LIVE)
        .then(r => {
          stateIsLoading.value = false;
          if (r) {
            if (r.value == '1') {
              testingState.value = '1';
            } else if (r.value == '0') {
              testingState.value = '0';
            } else {
              testingState.value = '1';
            }
          } else {
            testingState.value = '1';
          }
        });
    } else if (route.params.who == '242509') {
      store.state.isReena = true;
    }
  }, 250);

  setTimeout(() => {
    if (!isDev.value) {
      audioEl.value.oncanplay = audioCanPlay();
    }
  }, 1000);
})

const updateTesting = async () => {
  stateIsLoading.value = true;
  let update = await api(`/Settings/UpdateSettings`, { setting: 'testing', value: testingState.value == '1' ? '0' : '1' }); // For live
  // let update = await simulateAPI(2000, null); // Simulate for null
  // let update = await simulateAPI(2000, true); // Simulate for true
  // let update = await simulateAPI(2000, false); // Simulate for false
  stateIsLoading.value = false;

  if (update) {
    testingState.value = testingState.value == '1' ? '0' : '1';
  }
}

const updateTime = () => {
  startDateTimeIsLoading.value = true;
  endDateTimeIsLoading.value = true;

  api('/Settings/UpdateSettings', { setting: 'majlisstart', value: startDateTime.value }) // For live
  // simulateAPI(2000, null) // Simulate null
  // simulateAPI(2000, true) // Simulate true
  // simulateAPI(2000, false) // Simulate false
    .then(r => {
      startDateTimeIsLoading.value = false;

      if (!r) {
        getStartTime();
      }
    })

  api('/Settings/UpdateSettings', { setting: 'majlisend', value: endDateTime.value }) // For live
  // simulateAPI(2000, null) // Simulate null
  // simulateAPI(2000, true) // Simulate true
  // simulateAPI(2000, false) // Simulate false
    .then(r => {
      endDateTimeIsLoading.value = false;

      if (!r) {
        getEndTime();
      }
    })
}

const getStartTime = () => {
  api('/Settings/GetSettings', { setting: 'majlisstart' }) // For live
  // simulateAPI(2000, null) // Simulate null
  // simulateAPI(2000, { value: '' }) // Simulate empty value
  // simulateAPI(2000, { value: '2023-11-25 08:00' }) // Simulate fake value
    .then(r => {
      startDateTimeIsLoading.value = false;
      if (r) {
        if (r.value) {
          startDateTime.value = r.value;
        } else {
          startDateTime.value = '2023-12-24 11:00';
        }
      } else {
        startDateTime.value = '2023-12-24 11:00';
      }
    });
}

const getEndTime = () => {
  api('/Settings/GetSettings', { setting: 'majlisend' }) // For live
  // simulateAPI(2000, null) // Simulate null
  // simulateAPI(2000, { value: '' }) // Simulate empty value
  // simulateAPI(2000, { value: '2023-11-25 09:00' }) // Simulate fake value
    .then(r => {
      endDateTimeIsLoading.value = false;
      if (r) {
        if  (r.value) {
          endDateTime.value = r.value;
        } else {
          endDateTime.value = '2023-12-24 16:00';
        }
      } else {
        endDateTime.value = '2023-12-24 16:00';
      }
    });
}
const wishPhotoDownload = async () => {
  await api(`${store.state.wishPhotoSource}&download=true`)
}

watch(() => store.state.wishOrRSVPShow, (val) => {
  document.body.style.overflow = val ? 'hidden' : 'auto';
});

//#region Songs
const allSongs = ref([
  'A Thousand Years.mp3',
  'Afgan feat Rossa - Kamu Yang Kutunggu.mp3',
  'Aizat Amdan - Sampai Ke Hari Tua.mp3',
  'Ari Lasso feat Bunga Citra Lestari - Aku Dan Dirimu.mp3',
  'Cant Help Falling In Love - Live Session (Desmond Amos ft. DA Entertainment).mp3',
  'Cinta Luar Biasa - Andmesh Kamaleng (Saxophone Cover by Desmond Amos).mp3',
  'Rossa - Terlalu Cinta (Saxophone Cover by Dori Wirawan).mp3',
  'Shane Filan-Beautiful In White.mp3'
]);
const songIsPlaying = ref(false);
const audioEl = ref();
const audioSource = ref('');
const canPlay = ref(false);
const songIndex = ref(0);

const songPlayPause = () => {
  songIsPlaying.value = !songIsPlaying.value;
  if (songIsPlaying.value) {
    audioEl.value.play();
  } else {
    audioEl.value.pause();
  }

  if (showHashtag.value) {
    showHashtag.value = false;
    backgroundScale.value = '1.1';
  }
}

const audioCanPlay = () => {
  canPlay.value = true;

  if (!showHashtag.value) {
    songPlayPause();
  }
}

const audioEmptied = () => {
  canPlay.value = false;

  if (songIsPlaying.value) {
    songPlayPause();
  }
}

const audioEnded = () => {
  audioSource.value = '';
  songIndex.value++;
  if (songIndex.value >= allSongs.value.length) {
    songIndex.value = 0;
  }

  audioSource.value = allSongs.value[songIndex.value];

  setTimeout(() => {
    audioEl.value.oncanplay = audioCanPlay();
  }, 500);
}
//#endregion
</script>

<style scoped>
.app-container {
  width: 100vw;
  max-width: var(--maxwidth);
}
.background {
  position: fixed;
  width: 100vw;
  max-width: var(--maxwidth);
  height: 100vh;
  min-height: -webkit-fill-available;
  bottom: 0;
  transform: scale(1);
  transform-origin: 50% 100%;
  transition: var(--mainTrans);
  pointer-events: none;
}
.hashtag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4em;
  font-family: "Buffalo";
  color: rgb(109,58,9);
}
.front {
  z-index: 101;
}
.back {
  z-index: 91;
}
.mid {
  z-index: 95;
}
.main-router {
  position: relative;
  transition: var(--mainTrans);
}

.song-section {
  background-color: rgb(234, 153, 78);
  border-radius: 100%;
  height: 60px;
  width: 60px;
  max-height: 60px;
  max-width: 60px;
  position: fixed;
  z-index: 201;
  right: calc(50% - 30px);
  bottom: calc(50% - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: songSectionAnim 3s ease-out forwards;
  transition: 0.3s;
}
.song-section > img {
  position: absolute;
  object-fit: contain;
  height: 75%;
  width: 75%;
}
@keyframes songSectionAnim {
  to {
    bottom: 10px;
    right: 10px;
    transform: scale(0.5);
  }
}

.dev-section {
  position: absolute;
  height: 50px;
  width: 150px;
  top: calc(50% + 100px);
  left: calc(50% - 75px);
  z-index: 199;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dev-section > * {
  margin-top: 10px;
}
.dev-section > input {
  width: 100%;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: var(--mainTrans);
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.slideup-enter-active, .slideup-leave-active {
  transition: 3s ease-out;
}
.slideup-enter-from, .slideup-leave-to {
  transform: translateY(100%);
}

.playpause-enter-active, .playpause-leave-active {
  transition: 0.3s;
}
.playpause-enter-from, .playpause-leave-to {
  transform: rotate(90deg);
  opacity: 0;
}

.show-photo-container {
  position: fixed;
  z-index: 211;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: rgba(255,255,255,0.75);
  backdrop-filter: blur(5px);
}
.wish-photo-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  height: 30px;
}
.wish-photo-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(128, 128, 128, 0.75);
}
.wish-photo-download {
  position: absolute;
  bottom: 30px;
}
.wish-photo-popup-enter-active, .wish-photo-popup-leave-active {
  transition: 0.3s;
}
.wish-photo-popup-enter-from, .wish-photo-popup-leave-to {
  transform: scale(0);
  opacity: 0;
}
</style>

<style>
:root {
  --maxwidth: 750px;
  --mainTrans: 2s ease-in;
  --mainPadding: calc(25% + 75px) 15%;
}
body {
  margin: 0;
}
#app {
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
}
.error {
  color: indianred;
  font-size: 0.75em;
  text-align: center;
}
.success {
  color: limegreen;
  font-size: 0.75em;
  text-align: center;
}
input, select, textarea, .dropdown-main {
  box-sizing: border-box;
  width: calc(100%);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
}
input:focus, select:focus, textarea:focus {
  outline: none;
}
input:disabled, select:disabled, textarea:disabled {
  opacity: 0.5;
}

.main-button {
  padding: 5px 15px;
  background-color: rgb(255, 229, 229);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0.5em;
  border: 1px solid gray;
  transition: 0.3s;
  box-shadow: 5px 5px 5px gray;
}
.main-button * {
  margin: 0 !important;
}
.main-button:focus {
  outline: none;
}
.main-button:active {
  transform: scale(0.9);
  box-shadow: none;
}
button {
  font-family: 'Satisfy', cursive;
}

@font-face {
  font-family: "Buffalo";
  src: url('./assets/fonts/Buffalo/Buffalo-Regular.otf');
}
@font-face {
  font-family: "Buffalo Italic";
  src: url('./assets/fonts/Buffalo/Buffalo Italic.otf');
}
@font-face {
  font-family: "Citadel Script";
  src: url('./assets/fonts/Citadel/Citadel Script Std Regular.otf');
}
@font-face {
  font-family: "Droid Serif";
  src: url('./assets/fonts/DroidSerif/DroidSerif-Regular.ttf');
}
@font-face {
  font-family: "Droid Serif Bold";
  src: url('./assets/fonts/DroidSerif/DroidSerif-Bold.ttf');
}
@font-face {
  font-family: "Droid Serif Italic";
  src: url('./assets/fonts/DroidSerif/DroidSerif-Italic.ttf');
}
@font-face {
  font-family: "Droid Serif Bold Italic";
  src: url('./assets/fonts/DroidSerif/DroidSerif-BoldItalic.ttf');
}
@font-face {
  font-family: "Madelyn";
  src: url('./assets/fonts/Madelyn/Madelyn.otf');
}
.buffalo {
  font-family: "Buffalo";
}
.buffalo-italic {
  font-family: "Buffalo Italic";
}
.citadel {
  font-family: "Citadel Script";
}
.droid-serif {
  font-family: "Droid Serif";
}
.droid-serif-bold {
  font-family: "Droid Serif Bold";
}
.droid-serif-italic {
  font-family: "Droid Serif Italic";
}
.droid-serif-bold-italic {
  font-family: "Droid Serif Bold Italic";
}
.madelyn {
  font-family: "Madelyn";
}
.ibarra-real-nova {
  font-family: 'Ibarra Real Nova', serif;
}
.yeseva-one {
  font-family: 'Yeseva One', serif;
}

.loader {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 3px dashed black;
  box-sizing: border-box;
  animation: button-loader-anim 1s ease-in-out infinite;
}
@keyframes button-loader-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
