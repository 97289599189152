import axios from "axios";

export const api = (url, data) => {
  let res;
  let prom = new Promise(r => res = r);

  if (data) {
    axios.post(url, data).then(r => res(r.data)).catch(err => {
      console.error(`Error posting to ${url} with data ${typeof data == 'object' ? JSON.stringify(data) : data}  -  ${err}`);
      res(null);
    });
  } else {
    axios.get(url).then(r => res(r.data)).catch(err => {
      console.error(`Error getting from ${url}  -  ${err}`);
      res(null);
    });
  }

  return prom;
}

export const simulateAPI = (time, result) => {
  return new Promise(r => setTimeout(() => r(result), time));
}