import { createStore } from 'vuex'

export default createStore({
  state: {
    showWishPhoto: false,
    wishPhotoSource: '',
    isReena: false,
    wishOrRSVPShow: false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
